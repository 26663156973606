












































import {
  defineComponent, PropType, useContext, ref, watch,
} from '@nuxtjs/composition-api';
import {
SfInput,
} from '@storefront-ui/vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import BKButton from '~/components/BK/BKButton.vue';
import { ForgotPasswordFormFields } from './types';

export default defineComponent({
  components: {
    SfInput,
    BKButton,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    form: {
      type: Object as PropType<ForgotPasswordFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const formCopy = ref<ForgotPasswordFormFields>();
    const isRecaptchaSuccessful = ref(false);
    // @ts-ignore
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));

    watch(() => props.form, (newForm) => { formCopy.value = { ...newForm }; }, { immediate: true, deep: true });

    const onRecaptchaSuccess = (token: string) => {
      if (token) {
        isRecaptchaSuccessful.value = true;
      }
    };

    return {
      formCopy, isRecaptchaSuccessful, onRecaptchaSuccess, isRecaptchaEnabled,
    };
  },
});

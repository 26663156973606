

























































































































































import {
PropType,
defineComponent, ref,
useContext,
watch,
} from '@nuxtjs/composition-api';
import {
SfCheckbox,
SfInput,
} from '@storefront-ui/vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import BKButton from '~/components/BK/BKButton.vue';
import { FormName, RegisterFormFields } from './types';

export default defineComponent({
  components: {
    SfInput,
    SfCheckbox,
    BKButton,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    form: {
      type: Object as PropType<RegisterFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formCopy = ref<RegisterFormFields>();
    const isRecaptchaSuccessful = ref(false);
    // @ts-ignore
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));

    watch(() => props.form, (newForm) => { formCopy.value = { ...newForm }; }, { immediate: true, deep: true });
    const changeForm = (formToNavigateTo: FormName) => {
      emit('change-form', formToNavigateTo);
    };

    const onRecaptchaSuccess = (token: string) => {
      if (token) {
        isRecaptchaSuccessful.value = true;
      }
    };

    return {
      formCopy,
      changeForm,
      onRecaptchaSuccess,
      isRecaptchaSuccessful,
      isRecaptchaEnabled,
    };
  },
});

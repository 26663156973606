var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { _vm.$emit('submit', _vm.formCopy) })}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Your email')},model:{value:(_vm.formCopy.email),callback:function ($$v) {_vm.$set(_vm.formCopy, "email", $$v)},expression:"formCopy.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-password'),expression:"'login-modal-password'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","label":_vm.$t('Password'),"type":"password","has-show-password":""},model:{value:(_vm.formCopy.password),callback:function ($$v) {_vm.$set(_vm.formCopy, "password", $$v)},expression:"formCopy.password"}})]}}],null,true)}),_vm._v(" "),(_vm.showRecaptcha)?_c('recaptcha',{on:{"success":_vm.onRecaptchaSuccess}}):_vm._e(),_vm._v(" "),_vm._t("error"),_vm._v(" "),_c('BKButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-submit'),expression:"'login-modal-submit'"}],staticClass:"sf-button--full-width form__button",attrs:{"type":"submit","disabled":_vm.loading || invalid || (_vm.isRecaptchaEnabled && !_vm.isRecaptchaSuccessful),"loading":_vm.loading,"data-testid":"login-form-submit"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n          "+_vm._s(_vm.$t('Login'))+"\n        ")]},proxy:true}],null,true)})],2)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"action"},[_c('span',{staticClass:"underline cursor-pointer login-form__forgot-password-button",on:{"click":function($event){return _vm.changeForm('forgotPassword')}}},[_vm._v("\n      "+_vm._s(_vm.$t('Forgotten password?'))+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"bottom"},[_c('p',{staticClass:"bottom__paragraph"},[_vm._v("\n      "+_vm._s(_vm.$t('No account'))+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"text-lg font-normal underline cursor-pointer login-form__register-button",on:{"click":function($event){return _vm.changeForm('register')}}},[_vm._v("\n      "+_vm._s(_vm.$t('Register today'))+"\n    ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }